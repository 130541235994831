import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faBars,
	faCalendarDays,
	faChevronDown,
	faEnvelope,
	faPhone,
	faChevronUp,
	faGlobe,
} from '@fortawesome/pro-light-svg-icons';

import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faArrows,
	faEuroSign,
	faMinus,
	faPlay,
	faPlus,
	faSearch,
	faStar,
	faQuoteLeft,
	faStarHalf,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrows,
	faBars,
	faChevronUp,
	faChevronDown,
	faCalendarDays,
	faEnvelope,
	faEuroSign,
	faGlobe,
	faQuoteLeft,
	faFacebook,
	faInstagram,
	faLinkedin,
	faMinus,
	faPinterest,
	faPhone,
	faPlay,
	faPlus,
	faSearch,
	faSoundcloud,
	faSpotify,
	faTwitter,
	faUsers,
	faWhatsapp,
	faYoutube,
	faStar,
	faStarHalf,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
